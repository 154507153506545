<template>
  <div class="page-diy">
    <v-container>
      <div class="DIY-row single">
        <div class="wizard-body video">
          <p class="big-question">
            Please watch this Owner's Perks explanation.
          </p>
          <div class="video-wrapper">
            <video width="900" controls autoplay>
              <source src="../video/CFWizard2fast.mp4" type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          </div>

          <div>
            <b-button class="btn wizard-button btn-secondary" @click="showCashFlow23">
              Go to next step
            </b-button>
          </div>
          <p class="bottom-left">
            <a href="javascript:void(0)" @click="showTOC">Table of Contents</a>
          </p>
        </div>
      </div>

      <b-modal id="dlgHelp" hide-footer>
        <template #modal-title> DIY HELP </template>
        <div class="d-block text-center">
          <p>
            Nice work in completing the first section. You have two more to go.
            The next section gets into Owner’s Perks. These are things that you
            expense to the business, and importantly, you can go to and find in
            both the financial reports and your general ledger. IRS tax code
            allows, and your CPA has probably worked very hard to find great
            deductions that save you on your taxes by showing a reduced net
            income. Well, when you go to sell the business, banks and the SBA
            will allow you to add these personal expenses in the business back
            to Owner’s Cashflow. Basically, the company paid for them, but they
            were for your personal benefit. As you enter these expenses, make
            sure you include ANNUAL numbers for the whole year. For instance, if
            your car payment is $500 per month, but sure to enter $6,000 for the
            whole year. Some people do a lot of this, and others don’t do much
            at all. No judgement either way, the key thing is that if you have
            employed this strategy, until you do these add-backs, you are
            under-valuing your business. When you go to sell your business, you
            will need to prove these to both your buyer, their bank, and the
            SBA. Again, you can use estimates for these and go back later on to
            make the numbers more precise, but the more accurate the information
            input here, the more accurate the estimated business value will be.
          </p>
        </div>
        <b-button
          class="mt-3"
          block
          @click="$bvModal.hide('dlgHelp')"
          style="float: right"
          >Close</b-button
        >
      </b-modal>

      <v-dialog v-if="dlgTOC" v-model="dlgTOC" max-width="600" persistent>
        <v-card style="background-color: whitesmoke">
          <div
            class="outline wizard-header__wrapper"
            style="background-color: blue"
          >
            <p class="wizard-header">Table of Contents</p>
            <span @click="dlgTOC = false"><i class="fa fa-times"></i></span>
          </div>

          <div class="TOC">
            <ul class="mouse-pointer">
              <li>
                <a href="javascript:void(0)" @click="showIntro" class="TOC"
                  >1-1. INTRO PAGE</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showIntroVideo" class="TOC"
                  >1-2. INTRO VIDEO</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow13" class="TOC"
                  >1-3. BUSINESS NAME</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow14" class="TOC"
                  >1-4. YEAR</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow15" class="TOC"
                  >1-5. REVENUES</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow16" class="TOC"
                  >1-6. EMPLOYEE COUNT</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow17" class="TOC"
                  >1-7. NET INCOME</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow18" class="TOC"
                  >1-8. OWNER SALARY</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow19" class="TOC"
                  >1-9. AMORTIZATION & DEPRECIATION</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow110"
                  class="TOC"
                  >1-10. INTEREST EXPENSE
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow111"
                  class="TOC"
                  >1-11. NON-RECURRING</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow112"
                  class="TOC"
                  >1-12. REAL ESTATE</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow113"
                  class="TOC"
                  >1-13. REAL ESTATE RENT</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow21" class="TOC"
                  >2-1 OWNERS PERKS SECTION</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow22" class="TOC"
                  >2-2 OWNERS PERKS EXPLANATION VIDEO</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow23" class="TOC"
                  >2-3 CAR PAYMENTS</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow24" class="TOC"
                  >2-4 CAR INSURANCE</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow25" class="TOC"
                  >2-5 HEALTH INSURANCE</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow26" class="TOC"
                  >2-6 LIFE INSURANCE</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow27" class="TOC"
                  >2-7 RETIRMENT ACCOUNTS</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow28" class="TOC"
                  >2-8 CHARITABLE CONTRIBUTIONS</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow29" class="TOC"
                  >2-9 MEALS ENTERTAINMENT</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow210"
                  class="TOC"
                  >2-10 MEMBERSHIPS</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow211"
                  class="TOC"
                  >2-11 TRAVEL
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow212"
                  class="TOC"
                  >2-12 FAMILY COMPENSATION</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow213"
                  class="TOC"
                  >2-13 MISC EXPENESE</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow31" class="TOC"
                  >3-1 SECTION COMPLETE</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow32" class="TOC"
                  >3-2 VALUATION MULTIPLE IMPACTS VIDEO</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow33" class="TOC"
                  >3-3 CASHFLOW LEVEL</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow34" class="TOC"
                  >3-4 LABOR RELIANCE</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow35" class="TOC"
                  >3-5 DESIRABLE INDUSTRY
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow36" class="TOC"
                  >3-6 EMPLOYEE SCARCITY
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow37" class="TOC"
                  >3-7 CUSTOMER CONCENTRATION</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow38" class="TOC"
                  >3-8 SPECIALIZED</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow39" class="TOC"
                  >3-9 OWNER INVOLVEMENT1</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow310"
                  class="TOC"
                  >3-10 OWNER INVOLVEMENT2</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow311"
                  class="TOC"
                  >3-11 MANAGEMENT TEAM</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow312"
                  class="TOC"
                  >3-12 HIGHLY PROFITABLE</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow313"
                  class="TOC"
                  >3-13 FINANCIAL TRENDS</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow314"
                  class="TOC"
                  >3-14 AGE OF BUSINESS</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow315"
                  class="TOC"
                  >3-15 INTELLECTUAL PROPERTY</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="showCashFlow316"
                  class="TOC"
                  >3-16 DISRUPTOR</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow41" class="TOC"
                  >4-1 COMPLETE PAGE</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow42" class="TOC"
                  >4-2 CONFIRM 1</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow43" class="TOC"
                  >4-3 CONFIRM 2
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow44" class="TOC"
                  >4-4 CONFIRM 3</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow45" class="TOC"
                  >4-5 MENU</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow46" class="TOC"
                  >4-6 REQUEST REPORT
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow47" class="TOC"
                  >4-7 LEARN MORE</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCashFlow48" class="TOC"
                  >4-8 RETURN VISITOR</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="logOut" class="TOC"
                  >LOG OUT</a
                >
              </li>
            </ul>
          </div>
        </v-card>
        <div style="margin-top: 15px">
          <b-button class="wizard-button" @click="dlgTOC = false">
            Close
          </b-button>
        </div>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import CommonServices from "../service/CommonServices";
import { mask } from "vue-the-mask";
import { EventBus } from "../main";

export default {
  components: {},
  directives: {
    mask,
  },

  created() {
    this.checkPermissions(); 
    this.createQuestions(this.currentUserID);   

    //this.checkPermissions();
  },

  data() {
    return {
      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      isBroker: localStorage.getItem('role')==2,
      isSeller: localStorage.getItem('role')==3,  
      isBuyer: localStorage.getItem('role')==4,
      isDIY: localStorage.getItem('role')==7,
      role: localStorage.getItem('role'),
      valid: false,
      dialog: false,
      dialogDelete: false,
      dlgTOC: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      file: "",
      profileImageUrl: "",
      currentDeal: localStorage.getItem("Deal"),
      currentUserID: localStorage.getItem("userid"),
      show: false,
      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headerBgVariant: "success",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "success",
      footerTextVariant: "dark",
      errorTitle: "",
      errorText: "",
      dlgError: false,
      usCurrency: null,
    };
  },

  mounted() {},

  computed: {},

  methods: {
    checkPermissions() {
      console.log('UserID/RouteId',this.currentUserID,this.$route.params.id)
      if(this.currentUserID !==this.$route.params.id){
        if (this.isAdmin == "false"){
         this.$router.push("/");
        }
      }       
    },

    logOut() {
      localStorage.clear();
      EventBus.$emit("toggle-nav", null);
      this.$router.push({ path: "Login" });
    },
    


    createQuestions(userid) {
      CommonServices.putData("Functions", {
        id: 11,
        param_one_number: userid,
        param_two_number: this.wsData.work_year,
      })
        .then((response) => {
          console.log("Create Questions Response: ", response.id);
          this.getDealData();
          this.multipleData.company_name = localStorage.getItem("Deal");
        })
        .catch((error) => {
          console.log("putData Error: ", error);
        });
    },

    getDealData() {
      this.companyName = this.multipleData.company_name;
      CommonServices.getList(`MultipleDIY/?user_id=${this.$route.params.id}`)
        .then((res) => {
          if (res.count == 0) {
            //create records for this deal/year
            this.createQuestions(this.$route.params.id);
          } else {
            //console.log("Current Year: ", this.multipleData.work_year);
            //console.log("Yearly Data: ", res);
            this.multipleData = res.results;
            this.multipleData.company_name = localStorage.getItem("Deal");
          }
          console.log("multipleData:", this.multipleData);
          console.log("First Question:", this.multipleData[0].question_text);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    showIntro() {
      localStorage.setItem("welcomeBack", 0); //disable Welcome Back flag so that the welcome page does not naviage back to this page
      this.dlgTOC = false;
      this.$router.push(`/DIYWelcome/${this.$route.params.id}`);
    },

    showIntroVideo() {
      this.dlgTOC = false;
      this.$router.push(`/DIYIntroVideo/${this.$route.params.id}`);
    },

    showCashFlow13() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_1-3/${this.$route.params.id}`);
    },

    showCashFlow14() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_1-4/${this.$route.params.id}`);
    },

    showCashFlow15() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_1-5/${this.$route.params.id}`);
    },

    showCashFlow16() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_1-6/${this.$route.params.id}`);
    },

    showCashFlow17() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_1-7/${this.$route.params.id}`);
    },

    showCashFlow18() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_1-8/${this.$route.params.id}`);
    },

    showCashFlow19() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_1-9/${this.$route.params.id}`);
    },

    showCashFlow110() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_1-10/${this.$route.params.id}`);
    },

    showCashFlow111() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_1-11/${this.$route.params.id}`);
    },

    showCashFlow112() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_1-12/${this.$route.params.id}`);
    },

    showCashFlow113() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_1-13/${this.$route.params.id}`);
    },

    showCashFlow21() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_2-1/${this.$route.params.id}`);
    },

    showCashFlow22() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_2-2/${this.$route.params.id}`);
    },

    showCashFlow23() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_2-3/${this.$route.params.id}`);
    },

    showCashFlow24() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_2-4/${this.$route.params.id}`);
    },

    showCashFlow25() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_2-5/${this.$route.params.id}`);
    },

    showCashFlow26() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_2-6/${this.$route.params.id}`);
    },

    showCashFlow27() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_2-7/${this.$route.params.id}`);
    },

    showCashFlow28() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_2-8/${this.$route.params.id}`);
    },

    showCashFlow29() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_2-9/${this.$route.params.id}`);
    },

    showCashFlow210() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_2-10/${this.$route.params.id}`);
    },

    showCashFlow211() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_2-11/${this.$route.params.id}`);
    },

    showCashFlow212() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_2-12/${this.$route.params.id}`);
    },

    showCashFlow213() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_2-13/${this.$route.params.id}`);
    },

    showCashFlow31() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-1/${this.$route.params.id}`);
    },

    showCashFlow32() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-2/${this.$route.params.id}`);
    },

    showCashFlow33() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-3/${this.$route.params.id}`);
    },

    showCashFlow34() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-4/${this.$route.params.id}`);
    },

    showCashFlow35() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-5/${this.$route.params.id}`);
    },

    showCashFlow36() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-6/${this.$route.params.id}`);
    },

    showCashFlow37() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-7/${this.$route.params.id}`);
    },

    showCashFlow38() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-8/${this.$route.params.id}`);
    },

    showCashFlow39() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-9/${this.$route.params.id}`);
    },

    showCashFlow310() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-10/${this.$route.params.id}`);
    },

    showCashFlow311() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-11/${this.$route.params.id}`);
    },

    showCashFlow312() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-12/${this.$route.params.id}`);
    },

    showCashFlow313() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-13/${this.$route.params.id}`);
    },

    showCashFlow314() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-14/${this.$route.params.id}`);
    },

    showCashFlow315() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-15/${this.$route.params.id}`);
    },

    showCashFlow316() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_3-16/${this.$route.params.id}`);
    },

    showCashFlow41() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_4-1/${this.$route.params.id}`);
    },

    showCashFlow42() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_4-2/${this.$route.params.id}`);
    },

    showCashFlow43() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_4-3/${this.$route.params.id}`);
    },

    showCashFlow44() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_4-4/${this.$route.params.id}`);
    },

    showCashFlow45() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_4-5/${this.$route.params.id}`);
    },

    showCashFlow46() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_4-6/${this.$route.params.id}`);
    },

    showCashFlow47() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_4-7/${this.$route.params.id}`);
    },

    showCashFlow48() {
      this.dlgTOC = false;

      this.$router.push(`/DIY_4-8/${this.$route.params.id}`);
    },

    showTOC() {
      this.dlgTOC = true;
    },

    getYearlyData() {
      this.workYear = this.wsData.work_year;
      this.companyName = this.wsData.company_name;
      CommonServices.getList(
        `Cashflow/?work_year=${this.workYear}&deal_id=${this.$route.params.id}`
      )
        .then((res) => {
          console.log("Current Year: ", this.wsData.work_year);
          console.log("Res: ", res);
          if (res.count == 0) {
            this.resetItems();
            //this.wsData = res;
            //this.wsData = { ...this.wsData, ...res };
            console.log("Clean Worksheet", this.wsData);
            this.wsData.company_name = localStorage.getItem("Deal");
            this.wsData.deal_id = this.$route.params.id;
            this.wsData.work_year = this.workYear;

            //this.dialogPerks = true;
            //this.dialogPerks=false;
          } else {
            this.getData(res.results[0].id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    resetItems() {
      console.log("Resetting items...");
      this.wsData = {
        id: "",
        deal_id: this.$route.params.id,
        company_name: "",
        net_income: 0,
        salary_taxes: 0,
        amortization: 0,
        int_exp: 0,
        non_recur_exp: 0,
        car_pay_owner: 0,
        car_pay_Spouse: 0,
        car_ins_owner: 0,
        car_ins_spouse: 0,
        health_ins_owner: 0,
        health_ins_spouse: 0,
        life_ins_owner: 0,
        life_ins_spouse: 0,
        ira: 0,
        charitable_contrib: 0,
        meals: 0,
        memberships: 0,
        travel: 0,
        family_comp: 0,
        other: 0,
        other_a: 0,
        other_b: 0,
      };
    },

    saveFormData() {
      console.log("Saving CaseFlow Data for UserID: ", this.wsData.user_id);
      this.wsData.last_page = "DIY_2-2";
      if (this.wsData.id) {
        console.log(
          "Updating CaseFlow Data for UserID: ",
          this.wsData.user_id,
          this.wsData.id
        );
        console.log("CashFlow Dataset: ", this.wsData);
        CommonServices.updateData(this.componentURL_base, this.wsData)
          .then((response) => {
            const index = this.items.findIndex(
              (item) => item.id === response.id
            );
            this.$set(this.items, index, response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully updated";
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.response.data).forEach((key) => {
                this.snackText = error.response.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      } else {
        console.log("Adding CaseFlow Data for UserID: ", this.wsData.user_id);
        CommonServices.addRecord(this.componentURL_base, this.wsData)
          .then((response) => {
            this.items.push(response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully added";
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      }
    },

    getData(ID) {
      console.log("Cashflow Detail Response: ", "test");
      CommonServices.Detail(ID, this.componentURL_base)
        .then((res) => {
          console.log("Cashflow Detail Response: ", res);
          this.wsData = { ...this.wsData, ...res };
          //this.dialogPerks = true;
          this.$forceUpdate();

          //this.dialogPerks=false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style src="../css/styles.css" scoped></style>
